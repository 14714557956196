<template>
    <div>
        <v-progress-circular
            v-if="isQueryLoading"
            :width="4"
            :size="30"
            indeterminate
            color="primary" />
        <v-row v-if="items.length > 0">
            <v-col :cols="5">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-search"
                    label="Search"
                    single-line
                    hide-details />
            </v-col>
            <v-spacer />
            <v-col :cols="1">
                <v-btn
                    color="green"
                    dark
                    class="ml-2 float-right"
                    @click="download">
                    Export to Excel
                </v-btn>
            </v-col>

            <v-col :cols="12">
                <v-data-table
                    class="edit-data-table"
                    :headers="headers"
                    :items="items"
                    :search="search">
                    <template
                        v-for="header in headers"
                        v-slot:[getSlotName(header.value)]="{ item }">
                        <template v-if="header.hasOwnProperty('format')">
                            {{ header.format(item[header.value]) }}
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else-if="hasReportBeenRun && !isQueryLoading">
            <v-col>
                No records found
            </v-col>
        </v-row>
    </div>
</template>

<script>
import exporter from '../export/exporter.js'

export default {
    props: {
        items: {
            type: Array,
            default: null
        },
        headers: {
            type: Array,
            default: null
        },
        isQueryLoading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            search: '',
            hasReportBeenRun: false
        }
    },

    watch: {
        isQueryLoading() {
            if (this.isQueryLoading) {
                this.hasReportBeenRun = true;
            }
        }
    },

    methods: {
        download() {
            if (!this.items || this.items.length === 0) {
                return;
            }
            let self = this;
            let items = this.items.map(i => {
                let row = {};
                self.headers
                    .filter(e => e.text !== '')
                    .forEach(e => {
                        row[e.text] = i[e.value];
                    });
                return row;
            });

            exporter.downloadAsExcel({ title: this.title, items });
        },

        getSlotName(value) {
            return "item." + value;
        },
    }
}
</script>
